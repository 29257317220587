import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AxiosAPIClient, BASE_PATH, STATIC_CATALOG } from "./constants";

import { hasValue } from "./util/Functions";
import CatalogContext from "./contexts/CatalogContext";
import LoadMask from "./components/LoadMask";

import { useAuth } from "./auth/useAuth";
import ApiKeyContext from "./contexts/ApiKeyContext";
import ApiDrivenModel from "./models/ApiDrivenModel";
const PublicLayout = lazy(() => import("./layouts/public/PublicLayout"));
const ProtectedLayout = lazy(() => import("./layouts/protected/ProtectedLayout"));
const PrivateLayout = lazy(() => import("./layouts/private/PrivateLayout"));

function Appin() {
	const [catalogConfig] = React.useContext(CatalogContext);
	const [template, setTemplate] = React.useState(STATIC_CATALOG ? 'private' : false);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const redirect = urlSearchParams.get("redirect")
		&& decodeURIComponent(urlSearchParams.get("redirect"));

	const pathParts = window.location.pathname.trim('/').split('/').filter(hasValue);
	const { currentUser, setOrganizationMember } = useAuth();
	const protectedPageRoots = ['user', 'catalog', 'email-verification'];
	const publicPageRoots = ['login', 'register', 'reset-password', 'pin-login', 'public'];

	const API_KEY_CONTEXT = React.useContext(ApiKeyContext);
	ApiDrivenModel.CURRENT_CONTEXT = API_KEY_CONTEXT;

	const fetch_organization_member = async () => {
		const search_options = {
			filter: JSON.stringify([{
				property: '{profile}',
				operator: '=',
				value: "@profile_id"
			}]),
			paths: JSON.stringify({
				id: true,
				profile: {
					id: true,
					username: true,
					email: true,
					given_name: true,
					family_name: true,
					pronouns: true,
					picture: true,
					mobile_phone: true,
					last_activity: true,
					public_token: true,
				},
				organization: {
					id: true,
					active: true,
					address: true,
					administrative_area: true,
					country: true,
					formatted_address: true,
					locality: true,
					name: true,
					postal_code: true,
					sub_administrative_area: true,
					logo: true,
					branding: true,
					parent: {
						id: true,
						logo: true,
						branding: true
					}
				},
				role: {
					id: true,
					is_administrator: true,
					label: true
				},
				verified: true
			})
		};
		const response = await AxiosAPIClient.get('Organization_Member', {//gets all members associated with your organization and any child organiatio
			headers: { 'api-key': 1 },
			params: search_options
		})

		if (response.data && response.data.length) {
			return response.data[0];
		}
		return [];
	}

	React.useEffect(() => {

		//if localStorage isn't a proper object, then delete it.
		try {
			let localOrgMember = localStorage.getItem('organization_member:data');
			if (!JSON.parse(localOrgMember) || typeof JSON.parse(localOrgMember) !== 'object') {
				throw new TypeError('error with localStorage')
			}
		} catch (e) {
			localStorage.removeItem('organization_member:data');
		}

		//this usually won't run because localStorage is set upon logging in. 
		//this is jsut incase localStorage is edited by user, or localStorage is holding invalid JSON object
		if (currentUser && !localStorage.getItem('organization_member:data')) {

			fetch_organization_member().then(response => {
				localStorage.setItem('organization_member:data', JSON.stringify(response));
				setOrganizationMember(response);
			});
		} else if (currentUser && localStorage.getItem('organization_member:data')) {
			setOrganizationMember(JSON.parse(localStorage.getItem('organization_member:data')));
			fetch_organization_member().then(response => {
				if (JSON.stringify(response) != localStorage.getItem('organization_member:data')) {
					localStorage.setItem('organization_member:data', JSON.stringify(response));
					setOrganizationMember(response);
				}
			});
		}

		const getTemplate = () => {
			if (STATIC_CATALOG) {
				return setTemplate("private");
			}
			if (!pathParts.length || publicPageRoots.includes(pathParts[0])) {
				// if (!currentUser || !pathParts.length || publicPageRoots.includes(pathParts[0])) {
				return setTemplate("public");
			}
			return setTemplate(
				!pathParts.length || protectedPageRoots.includes(pathParts[0])
					? "protected"
					: "private"
			);
		}
		getTemplate();


		if (currentUser && window.location.pathname === "/login") {
			window.location = redirect || '/catalog/select';
		}

	}, [currentUser]);

	const getModule = template => {
		switch (template) {
			case 'public':
				return (<PublicLayout />);
			case 'protected':
				return (<ProtectedLayout />);
			case 'private':
				return (<PrivateLayout />);
			default: return null;
		}
	};

	const wrapperClasses = [
		"bossLiveCatalogWrapper",
		(((catalogConfig !== null) || (template !== "private")) && template)
	];
	return (
		<div className="_boss" id="_boss">
			<div className={wrapperClasses.filter(a => a).join(" ")}>
				<Router basename={BASE_PATH}>
					<Suspense fallback={<LoadMask show={true} />}>
						{getModule(template)}
					</Suspense>
				</Router>
			</div>
		</div>
	);
}
export default Appin;
